import type { ContextResponse } from 'o365.modules.Context.ts';

import { ref, readonly } from 'vue';
import { context } from 'o365-modules';

const reactiveContext: Ref<ContextResponse> = ref({
    name: context.name,
    id: context.id,
    idPath: context.idPath,
    orgUnit: context.orgUnit,
    domainId: context.domainId,
    isDomain: context.domainId === context.id
});
const readonlyContext = readonly(reactiveContext);

context.on('Change', (ctx) => {
    reactiveContext.value = ctx;
});

/**
 * A helper composable for getting current context in computed, watcher functions
 * Returns a reactive readonly ref
 */
export default function useContextRef() {
    return readonlyContext;
}

type Ref<T> = { value: T };